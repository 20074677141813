import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import tw from 'twin.macro';
import BlockContent from '@sanity/block-content-to-react';

import PageContainer from '../components/page-container';
import SEO from '../components/seo';
import Card from '../components/card';
import {
  TitleBar,
  PageTitle,
  SectionTitle,
  PageSection,
  FlexContainer,
  FlexItem,
  TextContainer,
  ImageContainer,
} from '../utils/elements.stc';

const CardContainer = tw(PageSection)`w-full bg-primary-100 md:pb-0 md:px-4`;
const CardTitle = tw(PageTitle)`md:text-center md:pb-8`;
const CardWrapper = tw(FlexContainer)`w-full`;
const HeaderContainer = tw(FlexContainer)`md:pb-0`;

const BasaltActivitiesPage = ({ data }) => {
  const {
    page: {
      seo: { title, description, keywords },
      headerImage: {
        alt: headerImageAlt,
        asset: { fluid: headerImageFluid },
      },
      _rawBody,
      areaLinks,
      recreation: {
        title: recTitle,
        body: recBody,
        image: {
          alt: recImageAlt,
          asset: { fluid: recImageFluid },
        },
      },
      dining: {
        title: diningTitle,
        body: diningBody,
        image: {
          alt: diningImageAlt,
          asset: { fluid: diningImageFluid },
        },
      },
      shopping: {
        title: shoppingTitle,
        body: shoppingBody,
        image: {
          alt: shoppingImageAlt,
          asset: { fluid: shoppingImageFluid },
        },
      },
    },
  } = data;

  return (
    <PageContainer>
      <SEO title={title} description={description} keywords={keywords} />
      <TitleBar>
        <PageTitle tw="font-light md:font-light">{title}</PageTitle>
      </TitleBar>
      <PageSection>
        <HeaderContainer>
          <FlexItem w23>
            <ImageContainer mb>
              <Img fluid={headerImageFluid} alt={headerImageAlt} />
            </ImageContainer>
            <TextContainer wfull tw="leading-7">
              <BlockContent blocks={_rawBody} />
            </TextContainer>
          </FlexItem>
          <FlexItem>
            <div tw="rounded mb-8 p-2 border-2 border-solid border-white shadow-lg bg-primary-100">
              <SectionTitle center>Area Information</SectionTitle>
              {areaLinks.map(link => (
                <a href={link.url} key={link.title} tw="block underline pb-2">
                  {link.title}
                </a>
              ))}
            </div>
          </FlexItem>
        </HeaderContainer>
      </PageSection>
      <CardContainer>
        <CardTitle>Activities</CardTitle>
        <CardWrapper>
          <Card title={recTitle} fluid={recImageFluid} alt={recImageAlt}>
            {recBody}
          </Card>
          <Card
            title={diningTitle}
            fluid={diningImageFluid}
            alt={diningImageAlt}
          >
            {diningBody}
          </Card>
          <Card
            title={shoppingTitle}
            fluid={shoppingImageFluid}
            alt={shoppingImageAlt}
          >
            {shoppingBody}
          </Card>
        </CardWrapper>
      </CardContainer>
    </PageContainer>
  );
};

export default BasaltActivitiesPage;

export const query = graphql`
  query basaltActivitiesPageQuery {
    page: sanityBasaltActivitiesPage {
      seo {
        title
        keywords
        description
      }
      headerImage {
        alt
        asset {
          fluid(maxHeight: 800) {
            ...GatsbySanityImageFluid
          }
        }
      }
      _rawBody
      areaLinks {
        title
        url
      }
      recreation {
        title
        body
        image {
          alt
          asset {
            fluid(maxWidth: 400) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
      dining {
        title
        body
        image {
          alt
          asset {
            fluid(maxWidth: 400) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
      shopping {
        title
        body
        image {
          alt
          asset {
            fluid(maxWidth: 400) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
  }
`;
