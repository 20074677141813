import React from 'react';
import Img from 'gatsby-image';
import { Link } from 'gatsby';
import tw, { styled } from 'twin.macro';

const CardWrapper = styled.div`
  ${tw`rounded-lg flex-1 md:mx-4 border-4 border-solid border-white mb-4`}
  background-color: #fff;
  min-width: 300px;
`;
const CardImageWrapper = styled.figure`
  max-height: ${props => (props.maxheight ? props.maxheight : '300px')};
  overflow: hidden;
  border-top-left-radius: 0.2rem;
  border-top-right-radius: 0.2rem;
  position: relative;
`;
const CardCaptionWrapper = styled.figcaption`
  ${tw`font-heading font-light bg-primary-600 text-center text-txt-200 p-3 uppercase text-xl`}
  color: rgba(235, 224, 213, 1);
  opacity: 90%;
  position: absolute;
  bottom: 0;
  width: 100%;
`;
const CardCaptionTitle = styled.span`
  color: rgba(235, 224, 213, 1);
`;
const CardBody = styled.div`
  ${tw`font-sans text-base leading-normal px-3 py-4 text-gray-800`}
`;

const Card = props => {
  const {
    children,
    title,
    fluid: cardImageFluid,
    alt,
    url = null,
    linkTitle = 'Learn More',
    maxheight = '300px',
  } = props;
  const match = /http/;
  const external = url && match.test(url);

  return (
    <CardWrapper>
      <CardImageWrapper maxheight={maxheight}>
        <Img alt={alt} fluid={cardImageFluid} css={tw`w-full`} />
        <CardCaptionWrapper>
          <CardCaptionTitle>{title}</CardCaptionTitle>
        </CardCaptionWrapper>
      </CardImageWrapper>
      <CardBody>
        {children}
        {url && !external && (
          <Link to={url} tw="block underline uppercase text-lg text-center">
            {linkTitle}
          </Link>
        )}
      </CardBody>
    </CardWrapper>
  );
};

export default Card;
